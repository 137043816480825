import React, {Fragment} from "react";
import {useQuery} from "@apollo/client";
import {Container, Row, Col, Carousel} from "react-bootstrap";

import VoucherModal from "./voucher";
import {useAsyncSetState} from "use-async-setstate";
import {getProductsQuery, getProductsResult} from "../logic/products";
import {setFormData, getFormData} from "../manager/form";

const ProductSelector = ({
  onSelectProduct,
  buildingCode,
  cisLink,
  selectedProducts,
  setSelectedProducts,
  index,
  setIndex
}) => {
  const [showVoucher, setShowVoucher] = useAsyncSetState(false);
  const {voucherCode = "", serviceAvailability, building} = getFormData();
  const variables = {
    buildingCode,
    voucherCode,
    showHidden: true,
  };

  const pageInfoQuery = useQuery(getProductsQuery, {
    variables,
  });

  if (pageInfoQuery.loading) {
    return <Fragment />;
  }

  const products = getProductsResult(pageInfoQuery);

  const planProducts = (products || []).filter(
    (product) => product.hidden === false && product?.type === "internet"
  );

  const monthly = selectedProducts.filter(({type, once}) => ["voip", "staticip"].includes(type) || !once)
    .reduce((prev, value) => prev + Number(value.value), 0);

  let initialPayment = selectedProducts.reduce(
    (prev, value) => prev + Number(value.value),
    0
  );

  let newConnectionProduct;

  if (serviceAvailability?.isNewConnection) {
    newConnectionProduct = products.find(
      (product) => product?.onlyNewConnection === true
    );

    if (newConnectionProduct) {
      setFormData({newConnectionProduct});
      if (
        (newConnectionProduct?.value || 0) > 0 &&
        !isNaN(newConnectionProduct?.value)
      ) {
        initialPayment += newConnectionProduct?.value;
      }
    }
  }

  const handleClickRemoveVoucher = () => {
    setFormData({voucherCode: ""});
    pageInfoQuery.refetch();
  };

  const handleCarouselClickNext = async () => {
    const newIndex = index < planProducts.length - 1 ? index + 1 : 0;

    await setIndex(newIndex);
    const product = planProducts[newIndex];
    let selectEvents = product?.selectEventsActivate || [];
    let eventProducts = selectEvents.map((se) =>
      (products || []).find((p) => p.name === se)
    );

    setSelectedProducts(eventProducts);
  };

  const handleCarouselClickPrevious = async () => {
    const newIndex = index > 0 ? index - 1 : (planProducts || []).length - 1;

    await setIndex(newIndex);
    const product = planProducts[newIndex];
    let selectEvents = product?.selectEventsActivate || [];
    let eventProducts = selectEvents.map((se) =>
      (products || []).find((p) => p.name === se)
    );

    setSelectedProducts(eventProducts);
  };

  const handleClickConfirm = async () => {
    const newProducts = selectedProducts.filter(
      (sp) => !sp.hidden
    );

    setFormData({
      selectedProducts: newProducts.concat([
        planProducts[index],
      ]),
      newConnectionProduct,
    });

    await onSelectProduct(planProducts[index]);
  }

  return (
    <Container className="my-2 mx-auto py-5 product-purchase-container">
      {showVoucher && (
        <VoucherModal
          setVoucher={() => {
            return pageInfoQuery.refetch({
              buildingCode,
              voucherCode,
            });
          }}
          onClose={() => setShowVoucher(false)}
        />
      )}
      <Row>
        <Col>
          <h1 className="text-center">Select Plan</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          {voucherCode && (
            <div className="text-center">
              <span className="badge badge-secondary p-2">Voucher Applied</span>
            </div>
          )}
          <div className="text-center">
            {!voucherCode ? (
              <button
                onClick={() => setShowVoucher(true)}
                className="btn btn-link"
                type="button"
              >
                Click here to apply voucher
              </button>
            ) : (
              <button
                onClick={handleClickRemoveVoucher}
                className="btn btn-link"
              >
                Click here to remove voucher
              </button>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Carousel
            interval={null}
            activeIndex={index}
            nextIcon={
              <i
                className="fas fa-angle-right carousel-control"
                onClick={handleCarouselClickNext}
              />
            }
            prevIcon={
              <i
                className="fas fa-angle-left carousel-control"
                onClick={handleCarouselClickPrevious}
              />
            }
          >
            {(planProducts || []).map((product) => {
              const [wholeNumber = "00", decimal = "00"] = `${
                product?.value || "0"
              }`.split(".");

              const [origWholeNumber = "00", origDecimal = "00"] = `${
                product?.originalValue || ""
              }`.split(".");

              return (
                <Carousel.Item key={product?.id}>
                  <Col xs={10} sm={8} lg={4} xl={4} className="d-block mx-auto">
                    <div className="p-0 mb-1 mx-auto mt-0 border-0 d-block">
                      <div className="plan-item text-center">
                        <div className="plan-title font-primary-bold">
                          {product.name || ""}
                        </div>
                        <div className="plan-price pt-1 position-relative">
                          <span className="price-value">
                            {`$${wholeNumber}`}
                          </span>
                          <span className="small-price">{`.${decimal}`}</span>
                          <span className="price-freq">/mo.</span>
                          {product.originalValue && (
                            <div className="orig-price_discounted">
                              {`${origWholeNumber}.${origDecimal}/mo.`}
                            </div>
                          )}
                        </div>
                        <div className="plan-rate pb-1">
                          {product.rateProfile?.name || ""}
                        </div>
                        <div className="plan-unlimited mb-3 pb-3">
                          Unlimited Data
                        </div>
                        <div className="plan-subtitle">
                          {product.description || ""}
                        </div>
                        <div className="plan-subtitle bold">
                          No lock-in contract
                        </div>
                      </div>
                    </div>
                  </Col>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Col>
      </Row>
      <Row>
        <Col xs={12} id="review">
          <div className="product-addons">
            <Fragment>
              <Addons
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                buildingCode={buildingCode}
                voucherCode={voucherCode}
                selectedProductIds={
                  (planProducts || [])[index]?.id
                    ? [(planProducts || [])[index]?.id]
                    : []
                }
              />
            </Fragment>
            {(newConnectionProduct?.value || 0) > 0 && (
              <div className="text-center pt-5">
                <div className="font-weight-bold">
                  {newConnectionProduct.name}
                </div>
                <div style={{fontSize: 20}}>
                  {`$${newConnectionProduct?.value}`}
                </div>
              </div>
            )}
            <div className="font-weight-bold text-center">
              Initial Payment (Including First Month)
            </div>
            <div className="text-center" style={{fontSize: 25}}>
              {`$${initialPayment + (planProducts[index]?.value || 0)}`}
            </div>
            <div className="font-weight-bold text-center">Monthly</div>
            <div className="text-center" style={{fontSize: 25}}>
              {`$${monthly + (planProducts[index]?.value || 0)}`}
            </div>
            <Row>
              <Col className="vw-ps-button">
                <button
                  className="vw-button btn-blue mx-auto my-3 d-block py-2 px-5"
                  onClick={handleClickConfirm}
                >
                  Confirm
                </button>
              </Col>
            </Row>
            <Row>
              <Col className="minimum text-center">
                The plan is month to month and automatically renewed.
                <div>
                  {`Total minimum cost is $${
                    initialPayment + (planProducts[index]?.value || 0)
                  } inc. GST.`}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-center">
                  <a
                    href={cisLink || "javascript:void(0);"}
                    target={cisLink ? "_blank" : ""}
                    className={`font-black underline ${
                      cisLink.trim() === "" && "cislink-disabled"
                    }`}
                  >
                    Click here to view the critical information summary.
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

const Addons = ({ selectedProductIds, buildingCode, voucherCode, selectedProducts, setSelectedProducts }) => {
  const variables = {
    buildingCode,
    voucherCode,
    showHidden: true,
    selectedProductIds: selectedProductIds,
  };

  const getProduct = useQuery(getProductsQuery, {
    variables,
  });

  if (getProduct.loading) {
    return <Fragment />;
  }

  const products = getProductsResult(getProduct);

  const hardwareProductsDisplay = products.filter(
    ({type}) => type === "hardware"
  );
  
  const addOnProductsDisplay = products.filter(({type}) =>
    ["voip", "staticip"].includes(type)
  );

  const handleToggleChange = (product) => {
    if (selectedProducts.filter((sp) => sp.id === product.id).length > 0) {
      return setSelectedProducts(
        selectedProducts.filter((sp) => sp.id !== product.id)
      );
    }

    const selectEvents = (product?.selectEventsActivate || []).map((se) =>
      products.find((p) => p.name === se)
    );

    let newSelectedProducts = selectedProducts.concat([
      product,
      ...selectEvents,
    ]);
    
    newSelectedProducts = newSelectedProducts.filter(
      (nsp, i) =>
        newSelectedProducts.findIndex((p) => nsp?.id === p?.id && !!nsp?.id) ===
        i
    );

    return setSelectedProducts(newSelectedProducts);
  }

  return (
    <Container className="addons-container pb-3">
      <Row className="pt-4">
        <Col
          xs={{span: 12}}
          sm={{span: 10, offset: 1}}
          md={{span: 8, offset: 2}}
          className="first-col"
        >
          {hardwareProductsDisplay.length > 0 && (
            <div className="title text-center font-weight-bold pt-3 pb-2">
              Hardware
            </div>
          )}
          <section className="text-center">
            {hardwareProductsDisplay.length > 0 &&
              hardwareProductsDisplay.map((product) => {
                return (
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={`${product.id}`}
                      checked={
                        selectedProducts.filter((sp) => sp.id === product.id)
                          .length > 0
                      }
                      onClick={() => handleToggleChange(product)}
                    />
                    <label
                      className="custom-control-label bold"
                      htmlFor={`${product.id}`}
                    >
                      {product.name}
                      <span className="ml-2">
                        {`($${parseFloat(product.value)}/${
                          product.once ? "Once" : "Monthly"
                        })`}
                      </span>
                    </label>
                  </div>
                );
              })}
          </section>
        </Col>
        <Col
          xs={{span: 12}}
          sm={{span: 10, offset: 1}}
          md={{span: 8, offset: 2}}
          className="second-col"
        >
          {addOnProductsDisplay.length > 0 && (
            <div className="title text-center font-weight-bold pt-3 pb-2">
              Additional Addons
            </div>
          )}
          <section className="text-center">
            {addOnProductsDisplay.length > 0 &&
              addOnProductsDisplay.map((product) => {
                return (
                  <div
                    key={product.id}
                    className="custom-control custom-switch"
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={`${product.id}`}
                      checked={
                        selectedProducts.filter((sp) => sp.id === product.id)
                          .length > 0
                      }
                      onClick={() => handleToggleChange(product)}
                    />
                    <label
                      className="custom-control-label bold"
                      htmlFor={`${product.id}`}
                    >
                      {product.name}
                      <span className="ml-2">
                        {`($${parseFloat(product.value)}/${
                          product.once ? "Once" : "Monthly"
                        })`}
                      </span>
                    </label>
                  </div>
                );
              })}
          </section>
        </Col>
      </Row>
    </Container>
  );
}

export default ProductSelector
