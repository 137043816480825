import React, { useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Formsy from "formsy-react";

import TextField from "./input";
import Modal from "./modal";
import {getFormData, setFormData} from "../manager/form";

export default function VoucherModal(props) {
  const {voucherCode} = getFormData();
  const { onClose, setVoucher } = props;
  const formRef = useRef(null);

  async function handleSubmit({voucher}) {
    if (!voucher) {
      return undefined;
    }
    try {
      setFormData({voucherCode: voucher});
      await setVoucher(voucher);
      return onClose();
    } catch (err) {
      console.log({err})
    }
  }

  return (
    <Modal
      title={"Apply Voucher"}
      show
      onClose={async () => onClose()}
      footer={
        <Container>
          <Row>
            <Col xs="auto ml-auto">
              <button
                className="btn-light vw-button btn btn-white"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  return onClose();
                }}
              >
                {"Cancel"}
              </button>
            </Col>
            <Col xs="auto">
              <button
                className="vw-button btn btn-blue"
                type="submit"
                onClick={() => formRef.current.submit()}
              >
                {"Apply"}
              </button>
            </Col>
          </Row>
        </Container>
      }
    >
      <Formsy ref={formRef} onValidSubmit={handleSubmit}>
        <Row className="field-row mb-2">
          <Col>
            <TextField
              noLabel
              label={"Voucher"}
              name="voucher"
              placeholder={"Please enter your voucher code"}
              defaultValue={voucherCode || ""}
              required
            />
          </Col>
        </Row>
      </Formsy>
    </Modal>
  );
}
